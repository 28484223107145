import React from 'react';

var Footer = function Footer(props) {
  return React.createElement("footer", {
    id: "footer"
  }, React.createElement("div", {
    className: "inner"
  }, React.createElement("ul", {
    className: "copyright"
  }, React.createElement("li", null, "\xA9 Hospitality Tribe"), React.createElement("li", null, "Built by ", React.createElement("a", {
    href: "https://breakpointt.studio"
  }, "Breakpointt Studio")))));
};

export default Footer;