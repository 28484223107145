import React from 'react';

var Contact = function Contact(props) {
  return React.createElement("section", {
    id: "contact"
  }, React.createElement("div", {
    className: "inner"
  }, React.createElement("section", null, React.createElement("form", {
    name: "contact-us-form",
    method: "post",
    "data-netlify": "true"
  }, React.createElement("div", {
    className: "field half first"
  }, React.createElement("label", {
    htmlFor: "name"
  }, "Name"), React.createElement("input", {
    type: "text",
    name: "name",
    id: "name"
  })), React.createElement("div", {
    className: "field half"
  }, React.createElement("label", {
    htmlFor: "email"
  }, "Email"), React.createElement("input", {
    type: "text",
    name: "email",
    id: "email"
  })), React.createElement("div", {
    className: "field"
  }, React.createElement("label", {
    htmlFor: "message"
  }, "Message"), React.createElement("textarea", {
    name: "message",
    id: "message",
    rows: "6"
  })), React.createElement("ul", {
    className: "actions"
  }, React.createElement("li", null, React.createElement("input", {
    type: "submit",
    value: "Send Message",
    className: "special"
  })), React.createElement("li", null, React.createElement("input", {
    type: "reset",
    value: "Clear"
  }))))), React.createElement("section", {
    className: "split"
  }, React.createElement("section", null, React.createElement("div", {
    className: "contact-method"
  }, React.createElement("span", {
    className: "icon alt fa-envelope"
  }), React.createElement("h3", null, "Email"), React.createElement("a", {
    href: "#"
  }, "contact@hospitalitytribe.com"))))));
};

export default Contact;