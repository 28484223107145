import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';

var Menu = function Menu(props) {
  return React.createElement("nav", {
    id: "menu"
  }, React.createElement("div", {
    className: "inner"
  }, React.createElement("ul", {
    className: "links"
  }, React.createElement("li", null, React.createElement(Link, {
    onClick: props.onToggleMenu,
    to: "/"
  }, "Home")), React.createElement("li", null, React.createElement(Link, {
    onClick: props.onToggleMenu,
    to: "/about"
  }, "About Us")), React.createElement("li", null, React.createElement(Link, {
    onClick: props.onToggleMenu,
    to: "/consulting"
  }, "Consulting & Services")), React.createElement("li", null, React.createElement(Link, {
    onClick: props.onToggleMenu,
    to: "/training"
  }, "Training & Education")), React.createElement("li", null, React.createElement(Link, {
    onClick: props.onToggleMenu,
    to: "/blog"
  }, "Our Blog"))), React.createElement("ul", {
    className: "actions vertical"
  }, React.createElement("li", null, React.createElement("a", {
    href: "#",
    className: "button special fit"
  }, "Get Started")), React.createElement("li", null, React.createElement("a", {
    href: "#",
    className: "button fit"
  }, "Log In")))), React.createElement("a", {
    className: "close",
    onClick: props.onToggleMenu,
    href: "javascript:;"
  }, "Close"));
};

Menu.propTypes = {
  onToggleMenu: PropTypes.func
};
export default Menu;