import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';

var Header = function Header(props) {
  return React.createElement("header", {
    id: "header",
    className: "alt"
  }, React.createElement(Link, {
    to: "/",
    className: "logo"
  }, React.createElement("strong", null, "Hospitality"), " ", React.createElement("span", null, "Tribe")), React.createElement("nav", null, React.createElement("a", {
    className: "menu-link",
    onClick: props.onToggleMenu,
    href: "javascript:;"
  }, "Menu")));
};

Header.propTypes = {
  onToggleMenu: PropTypes.func
};
export default Header;